/* CSS Document */


// .posrelative { position: relative !important; }
.inspection-report-html-content{


.mrg-MB {
    margin-bottom: 15px;
}

.pad-L {
    padding-left: 5px
}

.pad-R {
    padding-right: 5px
}

.pad-LR {
    padding-left: 5px;
    padding-right: 5px
}

/* SPRITE ICONS */
.tm_sprite { background-image: url(../../images/sprite-icon.png); background-repeat: no-repeat; display: inline-block }


.tm-checked { background-position: -0px -48px; width: 17px; height: 17px; position: relative; top: 4px }

/* Section 1 : CAR DETAILS*/

.white_box {
    background: #fff;
    border: 1px solid #e4e4e4;
    padding: 15px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
    border-radius: 2px;
}

/* Car Name */
h1.carTitle {
    font-size: 22px;
    color: #ef5a28;
    padding: 0;
    margin: 0;
    font-family: roboto-medium;
}

.largeTxt {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 5px !important;
}

/*.overall-rate { font-family: roboto-medium; display: block; position: relative; width: 100%; height: 42px; background: #fff; border: 1px solid #e0e0e0; border-radius: 2px; }
.oRating { background: #fff; height: 40px; width: 60%; text-align: left; line-height: 40px; font-size: 15px; color: #636363; text-transform: uppercase; float: left; border-bottom-left-radius: 1px; border-top-left-radius: 1px; padding-left: 8px; }
.gain-rate, .total-rate { float: left; width: 20%; height: 40px; line-height: 40px; text-align: center; font-size: 16px; color: #fff; }
.ovrall-rating { font-size:11px; display: block; text-transform: uppercase; color: #aaa }*/

.overall-rate {
    font-family: roboto-medium;
    display: block;
    position: relative;
    width: 100%;
    height: 42px;
}

.oRating {
    background: #fff;
    height: 30px;
    width: 50%;
    text-align: center;
    line-height: 30px;
    font-size: 12px;
    color: #eee;
    text-transform: uppercase;
    float: left;
    border-radius: 15px;
    margin-top: 5px;
    margin-left: 15%
}

.gain-rate {
    float: left;
    width: 30%;
    height: 30px;
    line-height: 30px;
    text-align: left;
    font-size: 16px;
    color: #333;
    margin-top: 5px;
    padding-left: 10px;
}

.ovrall-rating {
    font-size: 11px;
    display: block;
    text-transform: uppercase;
    color: #aaa
}

.label-poor {
    background-color: #fa6a6a
}

.label-fair {
    background-color: #ff872e
}

.label-good {
    background-color: #ffcb5c
}

.label-excellent {
    background-color: #9ac45a
}

/*.gain-rate { background: #E8734B; border-bottom-left-radius: 2px; border-top-left-radius: 2px  }
*/
.total-rate {
    background: #BF5C3B;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px
}

/* Gallery */
.gal-wrapper {
    background: #f9f9f9;
    position: relative;
    display: block;
}

.gal-wrapper ul {
    list-style: none;
    padding-left: 0px;
    margin: 0
}

.gal-wrapper ul li.gal-imgLarge {
    width: 100%;
    display: table;
    margin-bottom: 5px;
}

.gal-wrapper ul li.gal-imgSmall {
    width: 32.75%;
    float: left;
    margin-right: 0.875%
}

.gal-wrapper ul li:last-child.gal-imgSmall {
    margin-right: 0px;
    position: relative;
}

.gal-wrapper ul li.gal-imgLarge,
.gal-wrapper ul li.gal-imgSmall {
    display: table;
    background-color: #f9f9f9;
    text-align: center;
}

.gal-wrapper ul li.gal-imgLarge .gal-img,
.gal-wrapper ul li.gal-imgSmall .gal-img {
    display: table-cell;
    vertical-align: middle
}

.gal-wrapper ul li.gal-imgLarge {
    min-height: 345px;
    height: 345px;
}

.gal-wrapper ul li.gal-imgLarge .gal-img img {
    display: inline-block;
    max-height: 345px;
    // max-width: 613px;
    // width: 100%
}

.gal-wrapper ul li.gal-imgSmall {
    min-height: 113px;
    height: 113px;
}

.gal-wrapper ul li.gal-imgSmall .gal-img img {
    display: inline-block;
    max-height: 113px;
    max-width: 201px;
    width: 100%
}

.gal-overlay {
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 25px;
    text-align: center;
    vertical-align: middle;
}

.gal-txt {
    font-size: 12px;
    font-family: roboto-medium;
    text-transform: uppercase;
    color: #fff;
    display: block;
}

.mob-gal {
    display: none
}

.cur-zoom {
    cursor: zoom-in;
}

/* Top Feature */
.topfeature-wrapper {
    width: 100%;
    display: block;
    position: relative
}

.topfeature-wrapper ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.topfeature-wrapper ul li {
    float: left;
    width: 50%;
    margin-bottom: 20px;
    span {
        img {
            width: 20%;
        }
    }
}

.topfeature-wrapper ul li .featuretxt-large {
    color: #444;
    font-size: 14px;
    font-family: roboto-medium;
    display: inline-block;
    text-align: left;
    margin-left: 5px;
    line-height: 16px;
}

.topfeature-wrapper ul li .featuretxt-small {
    color: #a0a0a0;
    font-size: 12px;
    display: block;
    text-align: left;
    font-weight: bold
}

/* Trust Mark Expert */
.tm-expert-wrapper {
    padding: 10px;
    background: #fbfbfb;
    border: 1px solid #ddd;
    display: block;
    position: relative;
    border-radius: 2px;
}

.col-tm-orange {
    color: #ef5a28 !important;
}

.col-tm-gray {
    color: #444444 !important;
}

.tm-expert {
    display: inline-block;
    width: 84px;
    height: 88px;
    background: #f3f3f3;
    border: 1px solid #ddd;
    padding: 1px;
    margin-right: 10px
}

.tm-expert .expert-pic {
    display: table;
    width: 80px;
    height: 84px;
    text-align: center;
}

.tm-expert .expert-pic .expert-img {
    display: table-cell;
    vertical-align: middle
}

.tm-expert .expert-pic .expert-img img {
    display: inline-block;
    max-height: 84px;
    max-width: 80px;
}

.expert-details {
    display: inline-block;
    position: relative;
    vertical-align: top;
    text-align: left
}

h3.nameTitle {
    font-size: 18px;
    margin: 0;
    margin-bottom: 4px;
    padding: 0;
    font-family: roboto-medium
}

.ex-tag {
    font-size: 14px;
    color: #5aa000
}

.smallTxt {
    font-size: 14px;
    color: #787878;
    margin: 3px 0px
}

hr.seprator {
    margin: 10px 0px;
    border-color: #ddd
}

.tm-btnExpert {
    background: #e66437;
    color: #fff;
    border: 1px solid #d35124;
    opacity: .9;
    transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out
}

.tm-btnExpert:hover,
.tm-btnExpert:focus {
    color: #fff;
    background: #d35124;
}

.btn:focus {
    outline: 0;
}

.form-control,
.btn {
    border-radius: 2px
}

@media (max-width: 767px) {
    .largeTxt {
        overflow: visible;
        white-space: normal
    }

    .mob-gal {
        display: block;
    }

    .mob-gal li.gal-imgLarge {
        min-height: 160px !important;
        height: 160px !important;
        margin-bottom: 15px !important;
    }

    .mob-gal li.gal-imgLarge .gal-img img {
        max-height: 158px !important;
        max-width: 100% !important;
    }

    .gal-overlay {
        padding-top: 50px
    }

    .gal-txt {
        font-size: 14px
    }

    .topfeature-wrapper ul li {
        margin-bottom: 25px;
    }
}

@media (max-width: 991px) {
    h1.carTitle {
        margin-bottom: 15px;
        font-size: 18px;
    }

    .navbar-brand>img {
        height: 23px
    }
}


/* SECTION 2 :: SUMMARY */
h2.titleTxt {
    font-size: 20px;
    color: #525355;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    font-family: roboto-medium;
}

.tm-label-box {
    float: right
}

.tm-label {
    display: inline;
    padding: 2px 12px 4px;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.43215;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 1em;
    margin-left: 5px
}

.label-blue {
    background-color: #E8734B
}


.tm-summary {
    margin-bottom: 25px;
    position: relative;
    width: 100%;
    vertical-align: top;
    display: inline-block;
}

.sm-icon {
    width: 57px;
    height: 57px;
    border-radius: 50%;
    margin-right: 15px;
    border: 1px solid #ddd;
    background: #fff;
    text-align: center;
    line-height: 1.42857143;
    float: left
}

/* SUMMARY ICONS */
// .tm_sprite_coloured { background-image: url(../images/sprite-icon-colored.png); background-repeat: no-repeat; display: inline-block }

.body-frame {
    background-position: -3px -76px;
    width: 39px;
    height: 14px;
    position: relative;
    margin-top: 20px;
}

.engine {
    background-position: -49px -76px;
    width: 33px;
    height: 21px;
    position: relative;
    margin-top: 16px;
}

.suspension {
    background-position: -264px -79px;
    width: 28px;
    height: 28px;
    position: relative;
    margin-top: 14px;
}

.brakes {
    background-position: -50px -33px;
    width: 33px;
    height: 33px;
    position: relative;
    margin-top: 10px;
}

.transmission {
    background-position: -137px -178px;
    width: 32px;
    height: 21px;
    position: relative;
    margin-top: 19px;
}

.interior {
    background-position: -94px -38px;
    width: 23px;
    height: 31px;
    position: relative;
    margin-top: 12px;
}

.exterior {
    background-position: -129px -40px;
    width: 32px;
    height: 28px;
    position: relative;
    margin-top: 13px;
    margin-left: 3px
}

.electrical {
    background-position: -172px -42px;
    width: 26px;
    height: 26px;
    position: relative;
    margin-top: 13px;
}

.ac-heater {
    background-position: -222px -174px;
    width: 26px;
    height: 28px;
    position: relative;
    margin-top: 14px;
}

.steering {
    background-position: -209px -42px;
    width: 27px;
    height: 27px;
    position: relative;
    margin-top: 14px;
}

.battery {
    background-position: -219px -76px;
    width: 32px;
    height: 24px;
    position: relative;
    margin-top: 14px;
}

.tyres {
    background-position: -249px -41px;
    width: 32px;
    height: 32px;
    position: relative;
    margin-top: 12px;
}

.road-test {
    background-position: -174px -76px;
    width: 33px;
    height: 28px;
    position: relative;
    margin-top: 14px;
}

.lights {
    background-position: -95px -79px;
    width: 31px;
    height: 22px;
    position: relative;
    margin-top: 17px;
}

.documents {
    background-position: -139px -76px;
    width: 22px;
    height: 30px;
    position: relative;
    margin-top: 12px;
}

.other {
    background-position: -77px -231px;
    width: 26px;
    height: 30px;
    position: relative;
    margin-top: 12px;
}

.sm-right {
    float: left;
    width: 100%;
    padding-top: 5px;
}

.progressBox {
    width: 100%;
    display: inline-block;
    margin-right: 10px;
    margin-top: 5px;
}

h4.smTitle {
    font-size: 15px;
    color: #444;
    text-transform: uppercase;
    font-family: roboto-medium;
    margin: 0;
    margin-bottom: 5px
}

.sm-right .progress {
    border-radius: 16px;
    height: 10px;
    box-shadow: none;
    margin-bottom: 0px;
    background-color: #e4e4e4;
}

.sm-right .progress .progress-bar {
    box-shadow: none
}

.sm-ratingbox {
    display: inline-block;
    vertical-align: top;
    margin-top: 0px;
    position: absolute;
    top: 5px;
    right: 0;
}

.sm-ratingbox .sm-gain,
.sm-ratingbox .sm-total {
    width: 24px;
    height: 20px;
    float: left;
    text-align: center;
    line-height: 18px;
    color: #fff;
    font-size: 12px;
}

.sm-ratingbox .sm-gain {
    background-color: #40A9D6;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
}

.sm-ratingbox .sm-total {
    background-color: #1B7DA7;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
}


@media (max-width: 767px) {
    h2.titleTxt {
        margin-bottom: 15px;
    }

    .tm-label-box {
        float: none
    }

    .tm-label {
        padding: 2px 12px;
        margin-left: 0px
    }

    .sm-right {
        width: 70%;
        padding-top: 0px;
    }

    .progressBox {
        width: 100%
    }

    h4.smTitle {
        margin-bottom: 5px;
        font-size: 13px;
    }
}

/* SECTION 3 :: DENTS & DIPS */
.dd-wrapper {
    position: relative;
    display: table;
    width: 100%;
    height: 100%;
    text-align: center
}

.dd-wrapper .dd-imgBox {
    display: table-cell;
    vertical-align: middle
}

.dd-wrapper .dd-imgBox img {
    display: inline-block
}

.dd-label {
    display: inline;
    font-size: 14px;
    font-weight: normal;
    text-transform: capitalize;
    white-space: nowrap;
    vertical-align: baseline;
    font-family: roboto-medium;
    margin-left: 25px;
}

.dd-icon {
    width: 10px;
    height: 10px;
    line-height: 10px;
    display: inline-block;
    font-family: roboto-medium;
    font-size: 11px;
    text-align: center;
    border-radius: 50%;
    text-transform: uppercase
}

.col-dented {
    color: #666;
}

.col-rusted {
    color: #e75e1a;
}

.col-scratched {
    color: #db5940;
}

/* CAR PARTS */

.dd-bonnet {
    position: absolute;
    top: 49%;
    left: 35%
}

.dd-fr-screen {
    position: absolute;
    top: 40%;
    left: 43%
}

.dd-rr-screen {
    position: absolute;
    top: 40%;
    left: 61%
}

.dd-fr-bumper {
    position: absolute;
    top: 51%;
    left: 13%
}

.dd-roof {
    position: absolute;
    top: 48%;
    left: 51%
}

.dd-Rr-bumper {
    position: absolute;
    top: 50%;
    left: 85%
}

.dd-dicky {
    position: absolute;
    top: 44%;
    left: 81%
}

.dd-a-rh-pillar {
    position: absolute;
    top: 22%;
    left: 43%
}

.dd-b-rh-pillar {
    position: absolute;
    top: 22%;
    left: 51%
}

.dd-c-rh-pillar {
    position: absolute;
    top: 22%;
    left: 60%
}

.dd-a-lh-pillar {
    position: absolute;
    top: 75%;
    left: 43%
}

.dd-b-lh-pillar {
    position: absolute;
    top: 75%;
    left: 51%
}

.dd-c-lh-pillar {
    position: absolute;
    top: 73%;
    left: 60%
}

.dd-Rh-fender {
    position: absolute;
    top: 15%;
    left: 35%
}

.dd-Rh-fr-door {
    position: absolute;
    top: 12%;
    left: 46%
}

.dd-Rh-Rr-door {
    position: absolute;
    top: 12%;
    left: 54%
}

.dd-Rh-qtr-panel {
    position: absolute;
    top: 14%;
    left: 65%
}

.dd-Lh-fender {
    position: absolute;
    top: 82%;
    left: 34%
}

.dd-Lh-fr-door {
    position: absolute;
    top: 84%;
    left: 45%
}

.dd-Lh-Rr-door {
    position: absolute;
    top: 84%;
    left: 54%
}

.dd-Lh-qtr-panel {
    position: absolute;
    top: 82%;
    left: 66%
}

/* /.END */

.dd-spotIcon {
    cursor: pointer;
    transition: all 0.4s ease-in-out
}

.dd-dented {
    color: #666;
    background: #666;
    border: 2px solid #666666;
    transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out
}

.dd-rusted {
    color: #e75e1a;
    background: #e75e1a;
    border: 2px solid #e75e1a;
    transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out
}

.dd-scratched {
    color: #db5940;
    background: #db5940;
    border: 2px solid #db5940;
    transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out
}

.dd-spotIcon:hover.dd-dented {
    background: #666;
    color: #fff;
}

.dd-spotIcon:hover.dd-rusted {
    background: #e75e1a;
    color: #fff;
}

.dd-spotIcon:hover.dd-scratched {
    background: #db5940;
    color: #fff;
}

/* Image Preview */



@media (max-width: 767px) {
    .dd-label {
        margin-left: 10px
    }

    .mob-mrg {
        margin: 0 !important
    }

    .dd-icon {
        width: 16px;
        height: 16px;
        line-height: 13px;
        font-size: 10px;
        font-weight: bold;
    }
}

/* NEW DENTS & DIPS SECTION */
.new-dd-section .dd-wrapper {
    height: auto
}

.new-dd-section .dd-icon {
    width: 20px;
    height: 20px;
    line-height: 14px;
    font-size: 12px;
    color: #fff !important
}


/* SECTION 4 :: INSPECTION REPORT */
.ir-good {
    background-position: 0px -102px;
    width: 20px;
    height: 16px;
    position: relative;
    top: 4px
}

.ir-bad {
    background-position: -20px -102px;
    width: 20px;
    height: 16px;
    position: relative;
    top: 4px
}

.tm-body-frame {
    background-position: -2px -125px;
    width: 32px;
    height: 12px;
    position: relative;
    margin-right: 5px
}

.tm-engine {
    background-position: -77px -126px;
    width: 26px;
    height: 15px;
    position: relative;
    margin-right: 5px;
}

.tm-suspension {
    background-position: -209px -208px;
    width: 20px;
    height: 20px;
    position: relative;
    margin-right: 5px;
}

.tm-brakes {
    background-position: -143px -124px;
    width: 20px;
    height: 20px;
    position: relative;
    margin-right: 5px;
}

.tm-transmission {
    background-position: -72px -208px;
    width: 26px;
    height: 17px;
    position: relative;
    margin-right: 5px;
}

.tm-interior {
    background-position: -196px -124px;
    width: 18px;
    height: 20px;
    position: relative;
    margin-right: 5px
}

.tm-exterior {
    background-position: -245px -125px;
    width: 21px;
    height: 17px;
    position: relative;
    margin-right: 5px
}

.tm-electrical {
    background-position: -2px -147px;
    width: 20px;
    height: 20px;
    position: relative;
    margin-right: 5px
}

.tm-ac-heater {
    background-position: -3px -232px;
    width: 19px;
    height: 20px;
    position: relative;
    margin-right: 5px
}

.tm-steering {
    background-position: -53px -146px;
    width: 18px;
    height: 18px;
    position: relative;
    margin-right: 5px
}

.tm-battery {
    background-position: -144px -208px;
    width: 23px;
    height: 17px;
    position: relative;
    margin-right: 5px
}

.tm-tyres {
    background-position: -101px -146px;
    width: 18px;
    height: 18px;
    position: relative;
    margin-right: 5px
}

.tm-road-test {
    background-position: -245px -147px;
    width: 23px;
    height: 20px;
    position: relative;
    margin-right: 5px
}

.tm-lights {
    background-position: -150px -148px;
    width: 20px;
    height: 14px;
    position: relative;
    margin-right: 5px
}

.tm-documentations {
    background-position: -203px -148px;
    width: 15px;
    height: 20px;
    position: relative;
    margin-right: 5px
}

.tm-other {
    background-position: -54px -231px;
    width: 18px;
    height: 20px;
    position: relative;
    margin-right: 5px
}

.tm-inspection li:hover .tm-body-frame,
.tm-inspection li.active .tm-body-frame {
    background-position: -40px -125px;
}

.tm-inspection li:hover .tm-engine,
.tm-inspection li,
active .tm-engine {
    background-position: -109px -126px;
}

.tm-inspection li:hover .tm-suspension,
.tm-inspection li,
active .tm-suspension {
    background-position: -236px -208px;
}

.tm-inspection li:hover .tm-brakes,
.tm-inspection li:hover .tm-brakes {
    background-position: -168px -124px;
}

.tm-inspection li:hover .tm-transmission,
.tm-inspection li:hover .tm-transmission {
    background-position: -105px -208px;
}

.tm-inspection li:hover .tm-interior,
.tm-inspection li.active .tm-interior {
    background-position: -221px -124px;
}

.tm-inspection li:hover .tm-exterior,
.tm-inspection li.active .tm-exterior {
    background-position: -272px -125px;
}

.tm-inspection li:hover .tm-electrical,
.tm-inspection li.active .tm-electrical {
    background-position: -28px -147px;
}

.tm-inspection li:hover .tm-ac-heater,
.tm-inspection li.active .tm-ac-heater {
    background-position: -29px -232px;
}

.tm-inspection li:hover .tm-steering,
.tm-inspection li.active .tm-steering {
    background-position: -77px -146px;
}

.tm-inspection li:hover .tm-battery,
.tm-inspection li.active .tm-battery {
    background-position: -177px -208px;
}

.tm-inspection li:hover .tm-tyres,
.tm-inspection li.active .tm-tyres {
    background-position: -125px -146px;
}

.tm-inspection li:hover .tm-road-test,
.tm-inspection li.active .tm-road-test {
    background-position: -272px -147px;
}

.tm-inspection li:hover .tm-lights,
.tm-inspection li.active .tm-lights {
    background-position: -175px -148px;
}

.tm-inspection li:hover .tm-documentations,
.tm-inspection li.active .tm-documentations {
    background-position: -224px -148px;
}

.tm-inspection {
    border: 1px dashed #ddd;
    border-radius: 1px;
    background-color: #f9f9f9
}

.tm-inspection.nav-stacked>li+li {
    margin-top: 0px;
    border-top: 1px dashed #ddd;
}

.tm-inspection li a {
    border-radius: 0px;
    font-family: roboto-medium;
    font-size: 14px;
    color: #aaaaaa;
    padding: 10px 12px;
}

.tm-inspection li.active a,
.tm-inspection li.active a:focus,
.tm-inspection li.active a:hover {
    background-color: #fff;
    color: #ef5a28;
    border-right: 2px solid #ef5a28
}

.tm-inspection li a:focus,
.tm-inspection li a:hover {
    background-color: #fff;
    color: #ef5a28;
    border-right: 2px solid #ef5a28
}

.tm-inspection li .tab-rightarrow {
    background-position: -43px -106px;
    width: 7px;
    height: 11px;
    position: absolute;
    top: 50%;
    margin-top: -5px;
    right: 2px;
    display: none
}

.tm-inspection li:hover .tab-rightarrow,
.tm-inspection li:focus .tab-rightarrow,
.tm-inspection li.active .tab-rightarrow {
    display: block
}

h3.reportTitle {
    font-size: 16px;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    color: #565656;
    font-family: roboto-medium
}

.ir-tab .panel {
    border: 0px;
    margin-bottom: 10px;
    box-shadow: none
}

.ir-tab .panel-heading {
    border-top: 1px dashed #ddd;
    border-bottom: 1px dashed #ddd;
    background: transparent;
    padding: 12px 15px
}

.ir-tab .panel-body {
    padding: 15px 0px
}

.ir-tab ul.ir-list {
    list-style: none;
    padding-left: 0px;
    width: 100%
}

.ir-tab ul.ir-list li {
    display: block;
    margin-bottom: 10px;
    width: 50%;
    float: left;
    padding: 0 5px;
}

.brdr-R1 {
    border-right: 1px solid #eee
}

.brdr-L1 {
    border-left: 1px solid #eee;
    margin-left: -1px
}

.show-xs {
    display: none
}

.xs-inspection.navbar-toggle {
    float: none;
    border-color: #ddd;
    background: #fff;
    margin: 0;
    width: 100%;
    text-align: left;
    border-radius: 2px
}

.xs-ir-arrow {
    position: absolute;
    top: 50%;
    margin-top: -2px;
    right: 10px
}

/* Mobile List */
.lg-drpdwn-wrapper {
    display: block
}

.xs-drpdwn-wrapper {
    display: none
}

@media (max-width: 767px) {
    .show-xs {
        display: block
    }

    .xs-mgTop {
        margin-top: 15px;
    }

    .brdr-R1 {
        border-right: 0px
    }

    .brdr-L1 {
        border-left: 0px;
        margin-left: 0px
    }

    .tm-icon {
        display: none
    }

    .lg-drpdwn-wrapper {
        display: none
    }

    .xs-drpdwn-wrapper {
        display: block
    }


    /* ## Mobile Dropdown List of Users ## */

    .ms-list .btn-group {
        width: 100%
    }

    .ms-list .btn-group .custom-drpdwn {
        width: 100%;
        display: block;
        text-align: left;
        font-family: roboto-medium;
        font-weight: normal;
        padding: 12px 8px;
        background: #f9f9f9;
        color: #444;
        border-color: #ddd;
        box-shadow: none;
        border: 1px solid #ddd;
        border-radius: 2px;
        font-size: 14px;
        cursor: pointer;
        transition: all 0.3s
    }

    .ms-list .btn-group .custom-drpdwn:hover,
    .ms-list .btn-group .custom-drpdwn:focus {
        background: #fff;
        border-color: #ccc;
        color: #555;
        outline: 0px !important
    }

    .ms-list .btn-group .custom-drpdwn .caret {
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -2px;
    }

    .ms-list .btn-group .drpdwn-list {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        display: none;
        min-width: 100%;
        padding: 0px;
        margin: 1px 0 0;
        font-size: 14px;
        text-align: left;
        list-style: none;
        background-color: #fff;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        border: 1px dashed #ddd;
        border-radius: 2px;
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
        box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    }

    .ms-list .btn-group .drpdwn-list li {
        border-bottom: 1px dashed #ddd
    }

    .ms-list .btn-group .drpdwn-list li:last-child {
        border-bottom: 0px
    }

    .ms-list .btn-group .drpdwn-list li a {
        padding: 12px 10px;
        color: #444;
        text-decoration: none;
        background: #fff;
        display: block;
        transition: all 0.4s
    }

    .ms-list .btn-group .drpdwn-list li a:hover {
        background: #f9f9f9;
        color: #e66437
    }

    .ms-list .btn-group .drpdwn-list>.active>a,
    .ms-list .btn-group .drpdwn-list>.active>a:focus,
    .ms-list .btn-group .drpdwn-list>.active>a:hover {
        background-color: #f9f9f9;
        color: #e66437
    }

    /* ## end ## */
}


/* FEATURES */
.features-wrapper .panel {
    border: 0px;
    margin-bottom: 10px;
    box-shadow: none
}

.features-wrapper .panel-heading {
    border: 0px;
    background: transparent;
    padding: 15px 0px
}

.features-wrapper .panel-body {
    padding-bottom: 0px
}

.tm-features {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0
}

.tm-features li {
    display: list-item;
    float: left;
    width: 14.2857%;
    text-align: center;
    margin-bottom: 20px;
    font-size: 12px;
}

.tm-features li.disabled {
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter ….3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    filter: gray alpha(opacity=60);
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: .6;
}

// .feature-sprite { background-image:url(../images/feature-sprite.png); background-repeat: no-repeat; display: block; margin:0 auto; clear: both; width: 48px; height: 32px }
.feature-sprite.cupboard {
    background-position: center -7px;
}

.feature-sprite.fogLight {
    background-position: center -43px;
}

.feature-sprite.leatherSeat {
    background-position: center -84px;
}

.feature-sprite.sunRoof {
    background-position: center -127px;
}

.feature-sprite.alloyWheels {
    background-position: center -166px;
}

.feature-sprite.frontWiper {
    background-position: center -202px;
}

.feature-sprite.rearwiper {
    background-position: center -239px;
}

.feature-sprite.tubelessTyre {
    background-position: center -986px;
}

.feature-sprite.seatheightadjustment {
    background-position: center -1028px;
}

.feature-sprite.abs {
    background-position: center -277px;
}

.feature-sprite.centrallocking {
    background-position: center -315px;
}

.feature-sprite.childSafetyLock {
    background-position: center -832px;
}

.feature-sprite.airBag {
    background-position: center -394px;
}

.feature-sprite.driverairbag {
    background-position: center -394px;
}

.feature-sprite.passengerairbag {
    background-position: center -721px;
}

.feature-sprite.immobilizer {
    background-position: center -355px;
}

.feature-sprite.remoteBoot {
    background-position: center -758px;
}

.feature-sprite.safetyBelt {
    background-position: center -650px;
}

.feature-sprite.rearparking {
    background-position: center -906px;
}

.feature-sprite.musicSystem {
    background-position: center -429px;
}

.feature-sprite.displayscreen {
    background-position: center -466px;
}

.feature-sprite.powerDoorLock {
    background-position: center -501px;
}

.feature-sprite.powerseats {
    background-position: center -540px;
}

.feature-sprite.powersteering {
    background-position: center -579px;
}

.feature-sprite.powerwindows {
    background-position: center -613px;
}

.feature-sprite.gpsNav {
    background-position: center -868px;
}

.feature-sprite.cruisecontrol {
    background-position: center -940px;
}

.feature-sprite.steeringAdjustment {
    background-position: center -796px;
}

.feature-sprite.airCondition {
    background-position: center -687px;
}

.showMore {
    padding: 13px 16px;
    background: #f0f0f0;
    display: inline-block;
    border: 1px solid #ddd;
    color: #34aadc;
    text-align: center;
    cursor: pointer;
    font-size: 13px;
    border-radius: 2px;
    text-transform: uppercase;
    font-family: roboto-medium;
    transition: all 0.4s
}

.showMore .ic-more {
    display: block;
    margin: 0 auto;
    font-size: 20px;
    font-family: Roboto-Regular;
    width: 24px;
    height: 24px;
    line-height: 19px;
    border-radius: 50%;
    color: #34aadc;
    border: 2px solid #34aadc;
    margin-bottom: 5px;
}

.ic-plus {
    background-position: -2px -176px;
    width: 24px;
    height: 24px;
    display: block;
    margin: 0 auto;
    margin-bottom: 5px
}

.ic-minus {
    background-position: -62px -176px;
    width: 24px;
    height: 24px;
    display: block;
    margin: 0 auto;
    margin-bottom: 5px
}

.showMore:hover {
    background: #34aadc;
    border-color: #34aadc;
    color: #fff
}

.showMore:hover .ic-plus {
    background-position: -32px -176px;
}

.showMore:hover .ic-minus {
    background-position: -96px -176px;
}

.showMore:hover .ic-more {
    color: #fff;
    border-color: #fff;
}

/* Gallery */
.tm-gall .modal-content {
    border-radius: 4px;
}

#tm-photo-gallery .carousel-inner .img-lg-box {
    display: table;
    text-align: center;
    width: 100%;
    height: 490px;
}

#tm-photo-gallery .carousel-inner .img-lg-box .img-gal-lg {
    display: table-cell;
    vertical-align: middle
}

#tm-photo-gallery .carousel-inner .img-lg-box .img-gal-lg img {
    display: inline-block;
    max-height: 490px
}

#tm-photo-gallery .carousel-control.left,
#tm-photo-gallery .carousel-control.right {
    background-image: none;
    background-repeat: no-repeat
}

#tm-photo-gallery .carousel-control.left,
#tm-photo-gallery .carousel-control.right {
    background: rgba(239, 90, 40, 0.9);
    text-shadow: none;
    color: #fff;
    text-align: center;
    width: 34px;
    height: 34px;
    border-radius: 2px;
    line-height: 34px;
    font-size: 25px;
    bottom: 20px;
    top: auto;
    opacity: 1;
    transition: all 0.4s ease-in-out
}

#tm-photo-gallery .carousel-control.left {
    margin-left: 0px;
    top: 50%;
}

#tm-photo-gallery .carousel-control.right {
    top: 50%;
}

#tm-photo-gallery .carousel-control.left:hover,
#tm-photo-gallery .carousel-control.right:hover {
    background: rgba(211, 81, 36, 1);
}

@media (max-width: 767px) {
    #tm-photo-gallery .carousel-inner .img-lg-box {
        padding-bottom: 60px !important;
    }

    .carousel-txt {
        right: 0;
        left: 0;
        bottom: -20px;
        background: rgba(255, 255, 255, .7);
        padding-top: 0px;
    }

    .num {
        background: none;
        bottom: 0;
        padding: 6px 10px;
        position: relative !important;
    }

    .bannerText01 {
        background: none;
        padding: 0px 10px 6px !important;
        float: none !important;
    }

    .tm-features li {
        width: 50%
    }

    .f3-show,
    .f2-show {
        display: block !important
    }

    .showMore {
        display: none
    }

    #tm-photo-gallery .carousel-inner .img-lg-box {
        height: 240px
    }

    #tm-photo-gallery .carousel-inner .img-lg-box .img-gal-lg img {
        max-height: 240px
    }

    #tm-photo-gallery .carousel-control.left,
    #tm-photo-gallery .carousel-control.right {
        bottom: -10px
    }
}

@media (max-width: 767px) {

    #tm-photo-gallery .carousel-control.left,
    #tm-photo-gallery .carousel-control.right {
        top: 33%
    }

    #tm-photo-gallery .carousel-control.left,
    #tm-photo-gallery .carousel-control.right {
        width: 30px;
        height: 30px;
        line-height: 26px;
        font-size: 20px;
    }

    .num,
    .bannerText01 {
        font-size: 12px !important;
        background: transparent !important
    }
}


h1.carTitle {
    font-size: 22px;
    color: #ef5a28;
    padding: 0;
    margin: 0;
    font-family: roboto-medium;
    float: left;
}

.hidtxt {
    float: left;
    font-size: 22px;
    margin: -4px 10px;
    font-weight: bold;
}

.bannerText01 {
    background: #fff;
    color: #000;
    font-size: 16px;
    padding: 7px 20px;
    float: right;
    text-shadow: none;
    text-decoration: none;
}

.accidental {
    color: #DB3826;
    font-size: 12px;
    float: left;
    margin-top: 2px;
}

.accidental.non-accidental {
    color: #46b632;
}

.report-type {
    display: inline-block;
    padding: 0px 5px 2px 5px;
    text-align: center;
    background-color: #cccccc;
    font-size: 12px;
    margin-left: 10px
}

.report-type.Commercial {
    background-color: #f5a623;
}

.report-type.Private {
    background-color: #cccccc;
    display: block;
    float: left;
    clear: both;
    margin-left: 0
}

.report-type.Farm {
    background-color: #31b93e;
}

.report-type.Construction {
    background-color: #F54019;
}

.non-accidental {
    clear: both;
    float: left;
    display: inline-block;
}

.num {
    background: none repeat scroll 0 0 #fff;
    color: #000;
    font-size: 15px;
    margin-left: 20px;
    opacity: 0.85;
    padding: 8px;
    position: absolute;
    text-align: center;
    text-shadow: none;
    z-index: 999;
    font-weight: bold;
}

.carousel-txt {
    right: 4% !important;
    left: 2% !important;
}


@keyframes ex4 {

    /* CSS3 */
    0% {
        transform: scale(.9);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(.9);
    }
}

@-moz-keyframes ex4 {

    /* Firefox */
    0% {
        transform: scale(.9);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(.9);
    }
}

@-webkit-keyframes ex4 {

    /* Webkit */
    0% {
        transform: scale(.9);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(.9);
    }
}

.ex4 {
    animation: ex4 1s infinite;
    /* CSS3 */
    -moz-animation: ex4 1s infinite;
    /* Firefox */
    -webkit-animation: ex4 1s infinite;
    /* Webkit */
}

/* warranty page css */
.tp {
    margin-top: 30px;
}

.warrnty-txt {
    color: #555;
    font-weight: bold;
    font-size: 18px;
}

.warrnty-txt {
    color: #555;
    font-weight: bold;
    font-size: 18px;
}

.warrnty-txt1 {
    color: #555;
    font-weight: bold;
    font-size: 16px;
    text-align: left;
}

.call-txt {
    color: #e34238;
    font-size: 42px;
    font-weight: bold;
}

.call-txt1 {
    font-size: 40px;
    font-weight: bold;
}

.comp {
    display: none;
}

.ent {
    display: none;
}


.ico-txt {
    font-size: 18px;
    color: #555;
    text-transform: uppercase;
    font-family: roboto-medium;
    margin: 0;
    margin-bottom: 5px;
    text-align: left;
    text-decoration: underline;
}

.ico-content {
    font-size: 14px;
    text-align: justify;
}

.ico-box {
    margin-right: 10px;
    margin-top: 10px;
}

.ico-frm {
    width: 57px;
    height: 57px;
    margin-right: 15px;
    background: #fff;
    text-align: center;
    line-height: 1.42857143;
    float: left;
}

.str {
    top: 0;
    position: absolute;
}

.tm-summary-pd {
    position: relative;
    width: 100%;
    vertical-align: top;
    display: inline-block;
}

section {
    margin-top: 20px;
}

.icons {
    vertical-align: top;
    width: 15%
}

.newfeature-padL {
    padding-left: 0px
}


.rating.rate-8,
.rating.rate-7,
.progress-8,
.progress-7 {
    background: #CBC464;
}

.rating.rate-6,
.rating.rate-5,
.rating.rate-4,
.progress-6,
.progress-5,
.progress-4 {
    background: #D8BA98;
}

.rating.rate-3,
.rating.rate-2,
.rating.rate-1,
.progress-3,
.progress-2,
.progress-1 {
    background: #E69189;
}

.colorcode {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #fff;
    border: solid 1px #C6C6C6;
    border-radius: 50%;
}

@media (max-width :992px) {
    .topfeature-wrapper {
        padding: 15px;
    }
}

@media (max-width :767px) {
    .topfeature-wrapper {
        padding: 15px 10px 0px 10px;
    }
}


.season_tabs {
    position: relative;
    min-height: 360px;
    /* This part sucks */
    clear: both;
    margin: 25px 0;
}

.season_tab {
    float: left;
    clear: both;
    width: 220px;
}

.season_tab label {
    background: #eee;
    padding: 10px;
    border: 1px solid #ccc;
    margin-left: -1px;
    font-size: 21px;
    vertical-align: middle;
    position: relative;
    left: 1px;
    width: 264px;
    // height: 68px;
    display: table-cell;

    border-radius: 0px;
    font-family: roboto-medium;
    font-size: 14px;
    color: #aaaaaa;
    padding: 10px 12px;

    border: 1px dashed #ddd;
    border-radius: 1px;
    background-color: #f9f9f9;
    cursor: pointer;
}

.season_tab [type=radio] {
    display: none;
}

.season_content {
    position: absolute;
    top: 0;
    left: 225px;
    background: white;
    right: 0;
    bottom: 0;
    padding: 0 20px;
    // border: 1px solid #ccc;
    width: 710px;

    .panel-heading {
        border-top: 1px dashed #ddd;
        border-bottom: 1px dashed #ddd;
        background: transparent;
        padding: 12px 15px;
        margin-bottom: 10px;
    }

    ul {
        li {
            width: 50%;
            float: left;
        }
    }
}

.season_content span {
    animation: 0.5s ease-out 0s 1 slideInFromTop;
}

[type=radio]:checked~label {
    background: white;
    // border-bottom: 2px solid #8bc34a;
    z-index: 2;

    background-color: #fff;
    color: #ef5a28;
    border-right: 2px solid #ef5a28;

}

[type=radio]:checked~label~.season_content {
    z-index: 1;
}

.progress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    // background-color: #337ab7;
    // -webkit-box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
    // box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
    -webkit-transition: width .6s ease;
    -o-transition: width .6s ease;
    transition: width .6s ease;

    span {
        display: none;
    }
}

}