.top-heading-action-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;

  .action-btn {
    display: flex;
    button:disabled {
      cursor: not-allowed;
    }
    .btn-line {
      display: flex;
      align-items: center;
      margin-left: 10px;
      i {
        font-size: 22px;
      }
      .ic-share1 {
        font-size: 18px;
      }
      span {
        position: relative;
        top: 1px;
        margin-left: 10px;
      }
    }
  }
}

.top-ribben-search {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
}

.mandatory_images_hideshow {
    position: relative;
    .alert-danger {
        color: red;
    }

    i{
      font-size: 21px;
      position: absolute;
      top: -7px;
      right: -5px;
      cursor: pointer;
      background: #fff;
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 50%;
    }

    ul {

        li {
            display: inline-block;
            width: 20%;
        }
    }
}