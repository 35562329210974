.white-bg {
    background: $white;
    padding: 100px 0;
    text-align: center;
    height: 80vh;

    h1 {
        font-size: 80px;
    }

    h2 {
        font-size: 40px;
        padding-top: 30px;
    }
}

.top-heading {


    .nav-pills {
        padding-top: 25px;
    }
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 99;

    .modal-main {
        position: fixed;
        background: white;
        height: auto;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .close_icn {
        position: absolute;
        top: 20px;
        right: 20px;
        background: none;
        border: none;
        font-size: 21px;
        cursor: pointer;
    }

    .modal-body {
        max-height: 450px;
        overflow-y: auto;
        width: calc(100% + 80px);
        margin-left: -40px;
        padding: 0px 40px;
    }
}

.overflow-hidden {
    overflow: hidden;
}



.model-popup-outer {
    .modal-body {
        max-height: 470px;
        overflow-y: auto;
        width: calc(100% + 80px);
        margin-left: -40px;
        padding: 0px 40px;
    }

    .modal-main {
        border-radius: 8px;
        padding: 40px 40px;

        h2 {
            padding: 0px 0 24px 0px;
            font-weight: 600;
            font-size: 22px;
            line-height: 30px;
        }
    }


}

[hidden] {
    display: none;
}

.banner-preview-outer {
    cursor: pointer;

    span {
        font-size: 13px;
        color: #0bbddd;
    }
}

.loading {
    pointer-events: none;
}

.loading:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: hsla(0, 0%, 100%, .54);
    z-index: 997;
}

.loading:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -30px;
    margin-left: -30px;
    border-radius: 50%;
    border: 8px solid rgba(0, 0, 0, .12);
    border-top-color: #e46536;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    z-index: 998;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.m-t-40 {
    margin-top: 40px;
}

.h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #000;
    display: block;
    padding-bottom: 8px;
}

.logo-pen {
    width: 11px;
    height: 11px;
}

.tab-line {
    padding-bottom: 10px;

    .tab-list {
        display: flex;
        align-items: center;

        .nav-item {
            margin-right: 50px;
            cursor: pointer;

            .nav-link {
                border-bottom: 2px solid transparent;
                padding-bottom: 8px;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: rgba($heading-color, 0.87);

                &.active {
                    font-weight: 600;
                    color: rgba($accent-color, 0.87);
                    border-bottom: 2px solid $accent-color;
                }
            }
        }
    }

}


.accordion__panel {
    animation: fadein 35s ease-in;
    padding: 20px;
}

.add-inspection-accordion {
    margin-top: 20px;

    .accordion__item {
        background: $white;
        border-radius: 8px;
        margin-bottom: 20px;
    }

    .accordion__button {
        background: #E3E7F1;
        border-radius: 8px 8px 0 0;
        padding: 10px 60px 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
            color: $heading-color;
            font-weight: 600;
            font-size: 18px;
            line-height: 30px;
        }

        .error-label {
            color: red;
        }
    }

    .sub-accordion {
        .accordion__button {
            background: transparent;
            border-bottom: 1px solid $border-color;

            h2 {
                font-weight: normal;
                font-size: 16px;
            }
        }

        .accordion__item {
            border: 1px solid $border-color;
            border-radius: 8px;
        }
    }

    // .right-action-item {
    //     display: flex;
    //     align-items: center;

    //     li {
    //         margin-left: 40px;

    //         i {
    //             font-size: 20px;
    //         }

    //         .ic-delete-icn {
    //             font-size: 28px;
    //         }
    //     }
    // }
    .sub-accordion {
        .accordion__button {
            padding-right: 68px;
        }
    }
}

.ic-keyboard_arrow_down:before,
.ic-keyboard_arrow_up:before {
    position: relative;
    right: -20px;
    font-size: 30px;
}

.basic-detail-filter {
    display: flex;

    fieldset {
        width: 225px;
        margin-right: 8px;

        .react-select__control {
            border: 1px solid #bbbbbb;
            border-radius: 8px;
            box-shadow: 0 0 0;
            width: 100%;
            padding: 2px 15px;
            font-weight: 400;
            font-size: 12px;
        }

        .error-border {
            .react-select__control {
                border: 1px solid red;
            }
        }
    }

    .car-input-boxes {
        .car-field-box {
            margin-bottom: 30px;
            height: 42px;

            .react-select__placeholder {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            fieldset {
                width: 100%;
            }
        }

        .car-image-container {
            margin-bottom: 20px;

            .car-gallery {
                padding: 16px 10px 0;
                margin: 0px -8px;

                .car-image-box {
                    border: 1px solid #f0f0f0;
                    background: #c4c4c4;
                    border-radius: 8px;
                    min-width: 156px;
                    min-height: 120px;
                    margin: 0px 8px 16px;
                    position: relative;

                    i {
                        background: #1f1f1f;
                        height: 20px;
                        width: 20px;
                        position: absolute;
                        right: -6px;
                        top: -6px;
                        color: #fff;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100%;
                        cursor: pointer;
                    }

                    .del {
                        // position: absolute;
                        background: rgba(0, 0, 0, .6);
                        width: 100%;
                        height: 100%;
                        // top: 0;
                        // left: 0;
                        // display: none;
                        padding: 18px;
                        z-index: 1;

                        .del-txt {
                            color: #fff;
                            font-size: 14px;
                            margin-top: 12px;

                            p {
                                color: #fff;
                            }

                            ul {
                                list-style-type: none;
                                // margin: 20px auto;
                                // width: 100px;
                                padding: 0;

                                li {
                                    float: left;
                                    color: #000;
                                    margin-right: 10px;

                                    .delbox {

                                        padding: 3px 10px;
                                        cursor: pointer;
                                        border-radius: 3px;
                                        // border: 1px solid #169f85;
                                    }

                                    .del-yes {
                                        background: #0bbddd;
                                        color: #fff;
                                    }

                                    .del-no {
                                        background: #fff;
                                        color: #000;
                                    }
                                }
                            }
                        }
                    }


                }

                .image-tag-select {
                    margin: 0px 8px 16px;
                    width: 94%;
                }
            }

            .add-new-image {
                display: inline-grid;
                padding: 25px 8px;
                text-align: center;
                background: #f0f0f0;

                height: 150px;
                border-radius: 8px;
                margin: 15px 8px 16px;
                position: relative;

                i {
                    font-size: 28px;
                }
            }
        }
    }
}



.accordion__heading {
    cursor: pointer;
}

.updateModal .modal-main {
    width: 80%;
    height: 80% !important;
}


.car-image-container {
    fieldset {
        .react-select__control {
            padding: 0;
        }
    }
}

.inspection-location-selector {
    display: flex;
}

.mandatory-image-err-box .alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;

    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.edit-inspected-cars {
    .error-txt {
        font-size: 12px;
    }
}

.inspection-car-details-header {
    ul {
        li {
            display: inline-block;
            margin-right: 50px;

            .pq-sub-heading {
                max-width: 200px;
                white-space: nowrap;
                text-overflow: ellipsis;
                float: none;
                overflow-x: hidden;
            }

            .pq-sub-content {
                font-size: 12px;
                color: rgba(0, 0, 0, .54);

                img {
                    width: 30%;
                    cursor: pointer;
                }

                span {
                    color: #0bbddd;
                    cursor: pointer;
                }
            }
        }
    }
}


.qc-details-form {
    .tab-details {
        width: 100%;

        fieldset {
            margin-bottom: 20px;
        }

        .error-field {
            .inspection-dropdown {
                border-color: red;
            }
        }
    }

    .img-satisfy-blk-main {
        display: inline-block;

        .img-satisfy-blk {
            float: left;
            width: 100%;
            display: flex;
            align-items: center;
            padding-top: 10px;
            margin-bottom: 10px;
        }


        .clip-radio label {
            cursor: pointer;
            display: inline-block;
            font-size: 13px;
            margin-right: 15px;
            padding-left: 10px !important;
            position: relative;
            margin-bottom: 6px;
            line-height: 23px;
            color: rgba(0, 0, 0, .87);
            min-width: 100px;
        }


        .notsatisfy {
            margin: 0;
            font-size: 12px;
            color: rgba(0, 0, 0, .54);
            // display: none;
            float: left;
            padding-right: 10px;

            .checkbox {
                display: inline-block;

                label {
                    // padding-left: 5px;
                }

                input {
                    width: 0px;
                    height: 0px;
                }
            }

            .clip-check {
                margin-bottom: 10px;
                margin-top: 0;
                padding-left: 0;
                color: #545454 !important;

                label {
                    cursor: pointer;

                }
            }
        }
    }
}

.reqc_imageupload_taglist {
    .pull-right {
        float: right;
    }

    .upload-check {
        color: green;
        font-weight: bold;
        font-size: 16px;
    }
}

.ToggleSwitch {
    position: relative;
}

.ToggleSwitch .ToggleSwitch__wrapper {
    position: relative;
    width: 46px;
    height: 26px;
}

.ToggleSwitch .ToggleSwitch__wrapper .Slider.isChecked {
    background: rgba(11, 189, 221, .2);
    border-color: rgba(11, 189, 221, .2);
}

.ToggleSwitch .ToggleSwitch__wrapper .Slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: .4s ease;
}

.ToggleSwitch.ToggleSwitch__rounded .Slider {
    border-radius: 15rem;
    background: hsla(0, 0%, 61.6%, .2);
    border: 1px solid hsla(0, 0%, 61.6%, .2);
}

.ToggleSwitch .ToggleSwitch__wrapper .Slider.isChecked:before {
    left: calc(100% - 1.5rem);
    background: #0bbddd;
}

.ToggleSwitch .ToggleSwitch__wrapper .Slider:before {
    width: 21.33px;
    height: 21.33px;
    position: absolute;
    background: rgba(0, 0, 0, .5);
    content: "";
    margin: 0;
    padding: 0;
    top: 50%;
    left: 0.1rem;
    -webkit-transform: translateY(-51%);
    transform: translateY(-51%);
    transition: .4s;
    cursor: pointer;
}

.ToggleSwitch.ToggleSwitch__rounded .Slider:before {
    border-radius: 50%;
}


#inspection-request {
    background-color: #ffffff;
    // display: flex;

    .cards {
        // margin-bottom: 10px;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 16px;
        // float: left;
        width: 100%;
        border: 1px solid #e6e8e8;
        margin: 25px 0;

        .inspected-label {
            color: #fff;
            background-color: #199F2E;
            position: absolute;
            right: 25px;
            font-size: 11px;
            padding: 3px 8px;
            border-radius: 3px;
        }

        .rejected-label {
            color: #fff;
            background-color: #F51717;
            position: absolute;
            right: 25px;
            font-size: 10px;
            padding: 3px 8px;
            border-radius: 3px;
        }

        .assigned-label {
            color: #fff;
            background-color: #FFCC00;
            position: absolute;
            right: 25px;
            font-size: 10px;
            padding: 3px 8px;
            border-radius: 3px;
            cursor: pointer;
        }

        .inspect-status {
            position: relative;

            .img-cal-oval {
                position: absolute;
                right: 0;
                top: 3px;
                text-align: center;
            }
        }

        .action-btn {
            display: flex;

            .actionbtn {
                height: 42px;
                line-height: 42px;
                text-align: center;
                border: 1px solid #26B99A;
                -webkit-border-radius: 3px;
                border-radius: 3px;
                margin: 0 4px;
                // float: left;
                width: -webkit-calc(50% - 4px);
                width: calc(50% - 4px);
                margin-top: 20px;

                a {
                    text-transform: uppercase;
                    color: #26B99A;
                    display: block;
                }
            }
        }
    }

    .car-dtls {
        font-size: 14px;
        color: rgba(0, 0, 0, .54);
        width: 60%;
        display: inline-block;
        width: 100%;
        position: relative;
    }

    .inspect-status {
        border-top: 1px solid rgba(0, 0, 0, .12);
        margin-top: 10px;
        padding-top: 5px;
    }

    .un-call-btn {
        position: fixed;
        bottom: 0px;
        right: 0px;
        width: 100%;
        background: #26B99A;
        height: 50px;
        display: flex;

        a {
            color: #fff;
            text-align: center;
            margin: auto;
        }
    }
    
    
}

.ins-overlay {
    background: rgba(0,0,0,.7);
    width: 100%;
    height: 100%;
    z-index: 2;
    position: fixed;
    // display: none;
}

.reschedule-label {
    color: #fff;
    background-color: rgba(0, 0, 0, .54);
    position: absolute;
    font-size: 10px;
    padding: 3px 8px;
    border-radius: 3px;
    right: 0;
    top: 0;
}