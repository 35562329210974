.update-request-popup-popup, .inspection-report-document-images-popup, .view-status-modal {
    .modal-main {
        width: 1050px;
        border-radius: 8px;
        padding: 40px;
    }

    .modal-body {
        max-height: 650px !important;
    }

    .update-request-popup-content {
        display: flex;
        justify-content: space-between;

        .bulk-upload-sec {
            width: 465px;

            .image-uploader-wrapper {
                .display-box {
                    min-height: 467px;
                }
            }

            .dropzone-image-add {
                color: #646C7F;
                font-size: 16px;
                font-weight: 500;
                border: 1px dashed rgba(0, 0, 0, .34);
                margin-bottom: 15px;
                background: #f7f7f7;
                padding: 16px 5px 0;
                min-height: 150px;
                overflow: hidden;

                .dropzone {
                    text-align: center;
                    margin: 20px 0;
                }
            }
        }

        h3 {
            font-weight: 500;
            font-size: 22px;
            line-height: 30px;
            padding-bottom: 20px;
        }

        .bulk-history-sec {
            width: 465px;

            .bulk-history-table {
                background: #EFF2F9;
                border-radius: 8px;
                width: 100%;
                height: 400px;

                .data-table {
                    table {
                        tr {

                            th,
                            td {
                                &:nth-child(1) {
                                    width: 160px;
                                    padding-left: 20px;
                                }

                                &:nth-child(2) {
                                    width: 180px;
                                }

                                &:nth-child(3) {
                                    width: 110px;
                                }

                                &:nth-child(4) {
                                    width: 75px;
                                }
                            }

                            th {
                                position: sticky;
                                top: 0;
                                z-index: 10;
                                left: 0;
                                background: #e3e7f1;
                            }
                        }

                        thead {
                            display: block;
                        }

                        tbody {
                            display: block;
                            height: 410px;
                            overflow-y: auto;
                            border-right: 1px solid $border-color;
                            border-left: 1px solid $border-color;

                            tr {
                                display: table;
                                width: 100%;
                            }

                        }
                    }
                }
            }
        }

        .top-ribben-search .inspection-list-form .seller-opt {
            width: 50%;
        }

    }

    .history-no-data-txt {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: rgba($heading-color, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

    }
}

.view-status-modal {
    .update-request-popup-content{
        
        h3 {
            font-size: 18px;
        }
    }
}
.view-status-modal{
    .modal-main {
        width: 600px;
    }
}
.qc-bulk-image-uploader {
    .modal-main {
        width: 50%;
        height: auto !important;

        .update-request-popup-content {
            .bulk-upload-sec {
                width: 100%;

                .add-new-image {
                    text-align: center;
                    padding: 35px 0;

                }

                .car-image-container {
                    background: #fff;
                    border: 1px solid rgba(0, 0, 0, .12);
                    float: left;
                    width: 170px;
                    margin: 0px 7px 16px;

                    .car-gallery .car-image-box {
                        border: 1px solid #f0f0f0;
                        background: #c4c4c4;
                        border-radius: 8px;
                        min-width: 156px;
                        min-height: 120px;

                        position: relative;
                    }

                    .ic-clearclose {
                        cursor: pointer;
                    }
                }

                .error-note {
                    color: red;
                    font-size: 12px;
                    text-align: center;
                }

                .btn-submit-cancel {
                    text-align: center;
                }
            }

        }
    }
}

.car-gallery {
    position: relative;

    .delete-up-img {
        position: absolute;
        top: -5px;
        right: -5px;
        background: #fff;
        border: 1px solid rgba(0, 0, 0, .12);
        border-radius: 50%;
        text-align: center;
        width: 20px;
        height: 20px;
        font-size: 16px;
        line-height: 20px;
        color: rgba(0, 0, 0, .34);
        cursor: pointer;
        user-select: none;
        z-index: 9;
    }

    .react-select__menu {
        z-index: 9 !important;
    }

    .react-select__menu-list {
        max-height: 148px;
    }

}

.dropzone-image-add {
    .upload-image-input {
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
        top: 0;
        left: 0;
    }
}

.inspection-report-document-popup {
    .modal-main {
        width: 90%;

        iframe {
            height: 460px;
        }
    }
}

.yarl__slide_title{
    color: white;
    position: absolute;
    left: 10px;
    top: 10px;
}