.assignBox{
    .tab-content {
        background-color: #FFFFFF;
        position: relative; 
        min-height: 174px;
        height: 100%;
    }
    
    
    .card-head {
        color: #000000;
        font-size: 16px;
        font-weight: 500;
    }
    .dtList ul {
        margin-bottom: 16px;
    }
    
    .list-unstyled {
        padding-left: 0;
        list-style: none;
    }
    
    .dtList ul>li label {
        color: rgba(0,0,0,0.7);
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
    }
    
    .dtList ul>li p {
        color: rgba(0,0,0,1);
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        margin-bottom: 0;
    }
    
    .mrg-B15 {
        margin-bottom: 15px !important;
    }
    
    .edit-btn {
        // border-radius: 2px;
        // background: #0bbddd;
        // box-shadow: 0px 6px 6px rgba(11, 189, 221, 0.25);
        // color: #fff;
        // font-size: 12px;
        // font-weight: 400;
        // text-transform: uppercase;
        // padding: 3px 10px;
    }
    
    .edit-btn:hover, .edit-btn:focus, .btn-primary.edit-btn {
        // background-color: #26B99A;
        // color: #fff;
    }
    
    .btn {
        // transition: all 0.3s ease 0s !important;
        // background-image: none !important;
        // box-shadow: none !important;
        // outline: none !important;
        // position: relative;
    }

    .address-edit-icon {
        font-size: 16px;
        cursor: pointer;
    }

    .conveyanceWidth{
        width:40px;
        padding: 1px 5px;
    }
    // .saveconvence{
        // padding: 2px 7px;
        // border: 0 none;
        // background: #26B99A !important;
        // border-color: #26B99A !important;
        // color: #ffffff;
        // margin-left: 8px;
    // }
    // .assignBtn{
    //     padding: 6px 12px;
    //     border: 0 none;
    //     background: #26B99A !important;
    //     border-color: #26B99A !important;
    //     color: #ffffff;
    //     border-radius: 4px;
    // }
    .react-select__menu { z-index: 99;}

}



/*******************calnder css*****START*******************/

.calanderBox{
    .displayNone {display: none!important;}
    .tab-content {background-color: #FFFFFF;position: relative; }
    .calDate {text-align: center;margin-bottom: 16px;}
    .datemover {
        display: inline-block; 
        i {
            position: relative;
            top: 4px;
        }
    }
    .ic-keyboard_arrow_left{cursor: pointer;font-size: 22px;}
    .ic-keyboard_arrow_right{cursor: pointer;font-size: 22px;}
}

.calanderTable{ display: table; width: 100%; position: relative;}
.calanderRow{ display: table; width: 100%; position: relative; list-style: none;  margin: 0; padding: 0; height: 85px;}
.calanderRow>li {
    display: table-cell;
    position: relative;
    border-right: solid 1px rgba(0,0,0,0.12);
    padding: 5px;
    border-bottom: solid 1px rgba(0,0,0,0.12);color:rgba(0,0,0,0.8); transition: all 0.3s;
    height: 85px;
}
.calanderRow>li:first-child{border-left: solid 1px rgba(0,0,0,0.12);}
.calanderRow:hover{background: #f5f5f5;}
.calanderRow.calanderHeader:hover{ background: transparent;}
.calanderRow:first-child>li{border:none;border-bottom: solid 1px rgba(0,0,0,0.12); text-align: center; }
.calanderRow:first-child>li.timeSlot{color:rgba(0,0,0,0.8); }
.calanderRow>li:first-child,.calanderRow>li:last-child{width: 20%}
.calanderTable>.calanderRow:first-child>li, .calanderRow:first-child{height: inherit;}
.calanderRow .card-head{ margin-bottom: 0px;}
.cename{color: #000;}
.cename:hover{color:rgba(239, 92, 43, 1);}
.csnearme{ 
    margin-bottom: 5px; color: rgba(0,0,0,0.87);
    .cename {
        font-size: 12px;
    }
}
.csnearme .col1{float: left;width: 70%;}
.csnearme .col2{float: left;text-align: right;width: 30%;}
.ic-locality, .ic-location{ margin-right: 5px;}
.dealerVist{ margin: 0 0 0 5px; padding: 0; }
.morevisit{ max-width: 260px;top: -35px;left:-270px;min-width: 260px;}
.dealerVist>li{ margin-bottom: 5px; position: relative; list-style: none; text-indent: -15px; padding-left: 15px;}
/*.dealerVist>li:nth-child(n+3){ display: none;}*/
.morecount{ list-style: none;}
.calanderRow>li.last4, .calanderRow>li.colnone{ display:none;width: 0px !important; }

.schedule-box{ position: absolute;background-color: #1c2c35; color: rgba(255,255,255,0.8); font-size: 11px; top: 0; left: 0; width: 100%; height: 100%; padding: 10px 5px;z-index: 8;}

.schedule-box p{ margin: 0;}

.schedule-box p>strong{ font-size: 12px;color: rgba(255,255,255,1);}
.tagBox{ text-align: right;margin-top: -7px; margin-bottom: 5px;}
.tag-confirm{ display: inline-block;background-color: #5cb85c; padding: 2px 5px 2px 10px;font-size: 9px; color: #fff; font-weight: 700; margin-right: -5px; position: relative;}
.tag-confirm:before {
    content: "";
    display: block;
    left: 0px;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 5px solid #1c2d35;
    top: 0;
}
.schedule-box.preassign{ background-color: rgba(239, 92, 43, 0.54); border: dashed 1px #fff; z-index: 10;}
.schedule-box.reschedule{ background-color: rgba(239, 92, 43, 1);}
.schedule-box.preassign .tagBox, .schedule-box.reschedule .tagBox{display: none;}
.schedule-box:hover{ cursor: pointer; }
.schedule-box.overlapped{transition: all 0.3s ease-in-out; 
    animation: blink normal 1.5s infinite ease-in-out;
    
    animation-duration: .5s ;
}

@keyframes blink { 
   0% { border-color: rgba(255,0,0,1); background-color: rgba(246, 166, 143, 1);} 
   50% { border:solid 1px rgba(255,255,0,1); background-color: rgba(246, 166, 143, 0.9);} 
   100% { border-color: rgba(255,0,0,1); background-color: rgba(246, 166, 143, 1);} 
}


.calanderHeader{ display: table !important;}
.tableArrow{ position: absolute; width: 20px; background: #fff; height: 24px; padding-top: 4px; top: 0; z-index: 10; cursor: pointer; opacity: 0.8;}
.tableArrow.prearrow{ right: -22px; top: 2px;}
.tableArrow.nextarrow{ left: -22px; top: 2px;}
.tableArrow:hover{ opacity: 1;}
.calanderRow>li:first-child,.calanderRow>li:last-child {width: 20%;z-index:10;background:#fff;}
@media (min-width: 1280px){
    .cal-container{ width: 1250px;}
    .calanderRow>li:first-child,.calanderRow>li:last-child{ width: 198px;}
    .calanderRow>li.timeSlot{ width: 130px;}
}




.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 70px !important;
}
.react-datepicker__time-container {
    width: 72px !important;
}
.react-datepicker-popper {
    z-index: 99 !important;
}

.schedule-box {
    .address-line {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

/*******************Calnder css*****END*******************/


.ceinspection{
    .tab-content {background-color: #FFFFFF;position: relative; padding: 20px; }
    .card-head {color: #000000;font-size: 16px;font-weight: 500;margin-bottom: 15px;}
    .text-light {color: #c2c2c5 !important;}

    .dtList ul {margin-bottom: 15px;}
    .list-unstyled {padding-left: 0;list-style: none;}
    .dtList ul>li label {color: rgba(0,0,0,0.7);font-size: 12px;font-weight: 400;line-height: 14px;}
    .dtList ul>li p {color: rgba(0,0,0,1);font-size: 12px;font-weight: 700;line-height: 14px;margin-bottom: 0;}
    .daydate .date {font-size: 18px;font-weight: 700;line-height: 17px;}
    .daydate .day {font-size: 12px;font-weight: 400;line-height: 17px;opacity: 0.7;}
}

.btnWrapper{
justify-content: flex-end;
margin: 0 auto;
gap: 20px;
margin-top: 10px;
button{min-width: 100px;}
}

.alert-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    margin-top:50px;
}

