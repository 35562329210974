.edit-user-heading {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
        font-weight: normal;
        font-size: 16px;
        line-height: 30px;
        color: $heading-color;
    }
}

.edit-file-upload-bx {
    margin-bottom: 20px;
}

.edit-user-sec {
    background: $white;
    padding: 20px;
    border-radius: 8px;
    margin-top: 20px;
    display: inline-block;
    width: 100%;

    .image-uploader-wrapper {
        padding-top: 0px;
    }

    .upload-txt {
        .or-txt {
            padding-top: 10px;
        }

        .file-btn {
            margin-top: 15px;
        }
    }
}

.edit-user-list {
    h2 {
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        padding-top: 25px;
        color: rgba($heading-color, 0.87);
        padding-bottom: 20px;
        clear: both;
    }

    fieldset {
        margin-bottom: 25px;
    }

    .form-select-bx .form-label {
        top: -21px;
    }

    .edit-user-detail-form {
        .error-txt {
            position: absolute;
            bottom: -17px;
            left: 0px;
            font-size: 10px;
            color: #ff2e2e;
        }

        .error {
            input {
                border: 1px solid #ff2e2e;
            }

            .css-yk16xz-control {
                border: 1px solid #ff2e2e !important;
            }
        }

        .custom-control-label {
            &::before {
                width: 14px;
                height: 14px;
            }
        }

        .checkbox-filed {
            display: flex;
            align-items: center;
            cursor: pointer;

            input {
                cursor: pointer;
            }

            .custom-control-label {
                cursor: pointer;
            }
        }
    }

    .long-txt-error {
        margin-bottom: 35px;

        .long-error {
            bottom: -30px;
        }

        .error-txt {
            font-size: 9.7px;
        }
    }
}

.edit-form {
    .material {
        .form-input {
            margin-bottom: 0px;
        }

        .form-label {
            &:before {
                top: 11px;
            }
        }
    }

    .react-datepicker-wrapper {
        width: 100%;
    }

    .datepicker-cal {
        .form-label {
            top: -21px;
        }
    }

    .css-1uccc91-singleValue {
        padding-left: 5px !important;
    }

    .css-1wa3eu0-placeholder {
        padding-left: 5px !important;
    }

    .css-107lb6w-singleValue {
        padding-left: 5px !important;
    }

    textarea {
        width: 100%;
        border: 1px solid #bbbbbb;
        border-radius: 8px;
        box-shadow: 0 0 0;
        width: 100%;
        padding: 11px 15px;
        font-weight: 400;
        font-size: 12px;
        margin-bottom: 20px;
        height: 120px;
    }
}

.Summary-heading {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .btn-line {
        display: flex;
        align-items: center;

        i {
            font-size: 20px;
            margin-right: 5px;
        }

        span {
            position: relative;
            top: 2px;
        }
    }
}

.btn-submit-cancel {
    margin-top: 30px;

    .btn-line {
        min-width: 174px;
        margin-left: 20px;
    }
}

.css-1uccc91-singleValue~.form-label {
    top: -50px !important;
}

.material {
    &.animation-effect {
        .form-label {
            top: -0px !important;
        }
    }

    &.active-label {
        .form-label {
            top: -21px !important;
        }
    }
}

.top-ribben-search {
    .material {
        &.animation-effect {
            .form-label {
                top: -3px !important;
            }
        }
    }
}

.auction-dropdown__clear-indicator {
    &:after {
        content: "\e908";
        position: absolute;
        right: 34px;
        color: rgba($heading-color, 0.7);
        font-family: dc !important;
        font-size: 18px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.auction-dropdown__dropdown-indicator:after {
    content: "\e917";
    position: absolute;
    right: 3px;
    color: rgba($heading-color, 0.7);
    font-family: dc !important;
    font-size: 24px;
    top: 50%;
    transform: translateY(-50%);
}

.auction-dropdown__indicator svg {
    display: none;
}

.auction-dropdown__indicator-separator {
    position: relative;
    right: 20px;
}

.auction-dropdown__clear-indicator+.auction-dropdown__indicator-separator {
    position: relative;
    right: 20px;
    display: block;
}

.multiselect-dropDown {
    .css-1gpjby2 {
        svg {
            display: none;
        }

        &:after {
            content: "\e917";
            position: absolute;
            right: -8px;
            color: rgba($heading-color, 0.7);
            font-family: dc !important;
            font-size: 24px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .css-48ayfv+DIv {
        border-radius: 4px;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1);
        margin-top: 0px !important;
    }

    .css-1uccc91-singleValue {
        font-size: 12px !important;
        max-width: calc(100% - 11px) !important;
    }

    .css-26l3qy-menu {
        width: auto;
    }

    .css-9pfaa-MenuList {
        white-space: nowrap;
        font-size: 12px !important;
    }

    .css-11unzgr {
        font-size: 12px;
    }
}

.css-1fhf3k1-control {
    border-radius: 8px !important;
}

.delete-icn {
    height: 42px;
    border-radius: 8px;

    .btn-line {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: normal;
    }

    i {
        font-size: 22px;
    }

    span {
        position: relative;
        top: 2px;
        margin-left: 10px;
        text-align: center;
        font-size: 12px;
        display: inline-block;
        font-weight: 500;
        cursor: pointer;
    }
}

.css-1uccc91-singleValue {
    padding-left: 5px;
}

.css-109onse-indicatorSeparator {
    display: none;
}

.seller-opt {
    .material.active-label .form-label {
        top: -23px !important;
    }
}


.react-select__value-container--has-value .css-1wa3eu0-placeholder {
    top: -6px;
    background: $white;
    transition: all 0.15s ease-in-out;
    margin-left: 3px;
    font-size: 12px;
    padding: 0 3px;
    width: auto;
}

.react-select__control--menu-is-open .css-1wa3eu0-placeholder {
    top: -6px;
    background: $white;
    transition: all 0.15s ease-in-out;
    margin-left: 3px;
    font-size: 12px;
    padding: 0 3px;
    width: auto;
}


.react-select__value-container {
    overflow: inherit !important;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 99;

    .modal-main {
        position: fixed;
        background: white;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .close_icn {
        position: absolute;
        top: 20px;
        right: 20px;
        background: none;
        border: none;
        font-size: 21px;
        cursor: pointer;
    }

    .modal-body {
        max-height: 450px;
        overflow-y: auto;
        width: calc(100% + 80px);
        margin-left: -40px;
        padding: 0px 40px;
    }
}

.model-popup-outer {
    .modal-body {
        max-height: 470px;
        overflow-y: auto;
        width: calc(100% + 80px);
        margin-left: -40px;
        padding: 0px 40px;
    }

    .modal-main {
        border-radius: 8px;
        padding: 40px 40px;

        h2 {
            padding: 0px 0 24px 0px;
            font-weight: 600;
            font-size: 22px;
            line-height: 30px;
        }
    }
}


.confirmation-popup {
    .confirmation-btn {
        margin: 0 auto;
        text-align: center;

        .btn-primary,
        .btn-line {
            min-width: 125px;
            margin: 0px 7px
        }
    }

    .modal-main {

        h2 {
            font-weight: 500;
            font-size: 17px;
            line-height: 30px;
            text-align: center;
        }
    }

}

.react-select__group-heading {
    pointer-events: none;
    cursor: not-allowed !important;

    input {
        display: none;
    }
}

.react-select__option--is-disabled {
    cursor: not-allowed;
}

.qc-details-form {
    .tab-details {
        height: 70vh;
        overflow-y: auto;
        width: calc(100% + 15px);
        padding-right: 15px;
        padding-top: 5px;
    }
}