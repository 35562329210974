.dashboard-filter-top {
    background: $white;
    padding: 20px;
    border-radius: 8px;

    &.loan-filter-bg {
        fieldset {
            margin-bottom: 0px;
        }

        .more-filter {
            .dropdown.show {
                .dropdown-menu {
                    transform: translate(-60%, 40px) !important;
                }
            }

            fieldset {
                margin-bottom: 20px;
            }

            .btn-submit-reset {
                &.more-filter-btn {
                    margin-top: 0px;
                }
            }
        }

        .dateragefiled {
            width: 130px;
        }
    }
}

.payout-summary-tabs {
    background: $white;
    border-radius: 8px;

    .nav-pills {
        padding: 25px 30px 0;
        position: relative;
    }

    .tab-line {
        .tab-list {
            .nav-item {
                margin-right: 40px;
            }
        }
    }

    .tab-bg {
        .tab-list {
            .nav-item {
                .nav-link {
                    padding: 0px 10px;
                }
            }
        }
    }


}

.inspection-detail-table {
    padding-top: 30px;

    .custom-control {
        display: inline-block;
    }

    .ic-keyboard_arrow_down {
        font-size: 26px;
    }

    .data-table {
        table {
            tr {

                th,
                td {
                    &:nth-child(1) {
                        width: 10%;
                    }

                    &:nth-child(2) {
                        width: 15%;
                    }

                    &:nth-child(3) {
                        width: 15%;
                    }

                    &:nth-child(4) {
                        width: 15%;
                    }
                    
                    &:nth-child(5) {
                        width: 17%;
                    }

                    &:nth-child(6) {
                        width: 15%;
                    }

                    &:nth-child(7) {
                        width: 15%;
                    }

                    // &:nth-child(9) {
                    //     width: 9%;
                    // }

                    // &:nth-child(10) {
                    //     width: 9%;
                    // }

                    // &:last-child {
                    //     width: 11%;
                    // }

                    &.net-amt-txt {
                        background: rgba(255, 241, 214, 0.4);
                    }
                }

                td {
                    vertical-align: top;
                }

                th {
                    .info-icn {
                        display: inline-block;
                        position: relative;

                        .info-text-detail {
                            left: -180px;
                        }
                    }
                }

                .loan-id-arrow {
                    &::before {
                        content: "\e917";
                        font-family: dc !important;
                        font-size: 24px;
                        position: relative;
                        top: 6px;
                        margin-right: 8px;
                    }
                }

                &.active {
                    background: #F6F6F6 !important;
                    border-bottom: 0px;

                    .loan-id-arrow {
                        &::before {
                            content: "\e91a";
                            font-family: dc !important;
                            font-size: 24px;
                            position: relative;
                            top: 6px;
                            margin-right: 8px;
                        }
                    }
                }
            }

            .subtable {
                thead {
                    tr {
                        background: #E3E7F1;

                        th {
                            &:first-child {
                                padding-left: 50px !important;
                            }
                        }
                    }
                }

                td {
                    background: #F6F6F6;
                    padding-left: 30px !important;
                    padding-right: 30px !important;
                    padding-top: 0px;
                    vertical-align: middle;

                    .wrapper {
                        border: 1px solid #ddd;

                        .react-select__menu {
                            z-index: 99999999;
                            // position: fixed;
                        }
                    }
                    table {
                        tr {
                            th {
                                width: 10%;

                                &:nth-child(4) {
                                    width: 14%;
                                }

                                &:nth-child(8) {
                                    width: 11%;
                                }

                            }
                        }
                    }
                }

                table {
                    width: 100%;
                    // overflow-y: auto;
                    display: block;

                    tbody {
                        td {
                            background: $white !important;
                            padding-left: 20px !important;
                            padding-right: 20px !important;
                            padding-top: 13px;

                            &:first-child {
                                padding-left: 50px !important;
                            }
                        }
                    }
                }

                tr {
                    &.rejected {
                        td {
                            opacity: 0.4;

                            &:last-child {
                                color: #FF3636;
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            .ic-file_icn {
                position: absolute;
                margin-left: -22px;
                color: #000;
                font-size: 17px;
            }

            .ic-check {
                color: #59B74A;
            }

            .ic-clearclose {
                color: #FF3636;
            }
        }

        .link-txt {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .action-btn {
        display: flex;
        align-items: center;

        li {
            height: 32px;
            width: 32px;
            min-width: 32px;
            border-radius: 4px;
            border: 1px solid #e0e0e0;
            margin-right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            cursor: pointer;
            color: $heading-color;

            .ic-email1 {
                font-size: 22px;
            }

            .ic-otp {
                font-size: 23px;
            }
        }
    }
}

.inspection-request-add-stock {
    cursor: pointer;
    color: red;
}

.inspection-request-cancel-btn {
    cursor: pointer;
    color: blue;
}

.inspection-request-rc-preview {
    cursor: pointer;
    color: blue;
}

.Toastify__toast--success{
    background: #3b6e3b !important;
}

.Toastify__toast--error{
    background: #d94848 !important;
}



.active-dot:before, .inactive-dot:before {
    content: "";
    height: 8px;
    width: 8px;
    border-radius: 100%;
    margin-right: 2px;
    display: inline-block;
}

.active-dot:before {
    background: #0bbddd;
}
.inactive-dot:before {
    background: #e02020;
}


.Toastify__toast-body > div:last-child {
    word-break: break-word;
    flex: 1 1;
    color: white !important;
}