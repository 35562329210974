.container-fluid-full {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.add-user-bg {
    background: $white;
    padding: 30px;
    border-radius: 8px;
    display: inline-block;
    width: 100%;
    h3 {
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        color: rgba($heading-color, 0.87);
        padding-bottom: 20px;
    }
}
.add-user-form {
    display: inline-block;
    width: 100%;

    .material {
        margin-bottom: 25px;
        .form-label {
            &:before {
                top: 11px;
            }
        }
        .form-input {
            margin-bottom: 0px;
        }
    }

    .react-datepicker-wrapper {
        width: 100%;
    }
    .select-drop-down,
    .create-date {
        .material {
            .form-label {
                &:before {
                    top: -8px;
                }
            }
        }
    }
    .error-txt {
        position: absolute;
        bottom: -17px;
        left: 0px;
        font-size: 10px;
        color: #ff2e2e;
    }
    .error {
        input {
            border: 1px solid #ff2e2e;
        }
        .css-13cymwt-control {
            border: 1px solid #ff2e2e !important;
        }
    }
}
.select-drop-down,
.create-date {
    .material {
        .form-label {
            &:before {
                top: 11px;
            }
        }
    }
}
