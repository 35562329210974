.form-grid-container{
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-row-gap: 20px;
    grid-column-gap: 250px;
    justify-content: center;
    align-content: center;
}

.browse-btn {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;

    span.label {
        position: relative;
        z-index: 0;
        background: #FFFFFF;
        border: 1px solid #8C8C8C;
        border-radius: 8px;
        height: 44px;
        line-height: 44px;
        font-weight: 500;
        font-size: 13px;
        color: $heading-color;
        cursor: pointer;
        min-width: 115px;
        display: block;
    }

    .upload-box {
        display: inline-block;
        position: absolute;
        width: 50%;
        height: 44px;
        top: 20;
        left: 0;
        opacity: 0;
        cursor: pointer;
    }
}

.browse-file{
    display: grid;
    grid-template-columns: 1fr 2fr;
}