.roaster-btn{
    .btn-primary{
        height: auto !important;
        padding: 10px 0px;
    }
}

.roster-temp ul>li{
    list-style:disc !important

}