.top-ribben-search {
  background: $white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  .inspection-list-form {
    display: flex;
    align-items: center;
    fieldset {
      padding: 7px 0px;
    }
    .material {
      margin-right: 8px;
      .form-input {
        margin-bottom: 0px;
      }
    }
    .search-bx {
      width: 16.5%;
    }
    .seller-opt {
      width: 14%;
    }
    .post-action-opt {
      width: 16.5%;
    }
    .model-opt {
      width: 15.75%;
    }
    .create-date {
      width: 14%;
    }
    .registartion-date {
      width: 14.21%;
    }
    .created-opt {
      width: 14.02%;
    }
    .multiselect-dropDown {
      .inspection-dropdown__single-value {
        font-size: 12px !important;
        max-width: calc(100% - 5px) !important;
      }
      .inspection-dropdown__menu-list {
        white-space: normal;
      }
    }
  }
}

.btn-submit-reset {
  width: auto;
  .btn-primary {
    min-width: 88px;
    margin-right: 10px;
  }
  .btn-reset {
    background: transparent;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    border: 0px;
    color: $heading-color;
    cursor: pointer;
  }
}


.multiselect-dropDown {
  .css-1r4vtzz,
  .css-48ayfv {
    height: 42px;
    border: 1px solid #bbbbbb !important;
    border-radius: 8px !important;
    background: transparent;
    width: 100%;
    box-shadow: none;
  }
  .css-1r4vtzz {
    padding-left: 15px !important;
  }
  .css-1jllj6i-control {
    // width: 100%;

    box-shadow: none;
  }
  .css-6y1x9t-control {
    border-color: hsl(0, 0%, 80%) !important;
    box-shadow: none;
    &:hover {
      border-color: hsl(0, 0%, 80%) !important;
      box-shadow: none;
    }
  }
  .css-1v99tuv,
  .css-1gpjby2 {
    font-size: 12px;
    position: relative;
    top: -2px;
    font-weight: normal !important;
    color: #000 !important;
  }

  input {
    appearance: auto;
  }
  .css-1qprcsu-option {
    font-weight: normal;
  }
  .css-4ljt47-MenuList {
    white-space: nowrap;
  }
  .css-9gakcf-option {
    background: $accent-color;
  }
  .css-1yeojqj-group {
    background: #ccc;
  }
}


.css-1u9des2-indicatorSeparator {
    display: none;
}


.vehicle-listing-box {
    .top-ribben-search {
      .inspection-list-form {
        flex-wrap: wrap;
      }
    }
}

.datepicker {
    input {
      width: 100%;
      border: 1px solid #bbbbbb;
      border-radius: 8px;
      box-shadow: 0 0 0;
      width: 100%;
      padding: 11px 15px;
      font-weight: 400;
      font-size: 12px;
      background: url(../../images/date-icon.png) no-repeat right 0px center;
    }
    ::-webkit-input-placeholder {
      /* Edge */
      color: $heading-color;
      font-size: 12px;
    }
  
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $heading-color;
      font-size: 12px;
    }
  
    ::placeholder {
      color: $heading-color;
      font-size: 12px;
    }
}


.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #0bbddd !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #0bbddd !important;
}


.inspection-reg-no span.edit-click {
    position: absolute;
    right: 5%;
    top: 30%;
    cursor: pointer;
}
