.edit-user-sec {
    .image-slider-box {
        padding-left: 11px;
        padding-right: 10px;
    }
}

.gallery-overlay {
    width: -webkit-calc(100% - 30px);
    width: calc(100% - 30px);
    height: 46px;
    position: absolute;
    z-index: 1;
    background: linear-gradient(to top, rgba(0, 0, 0, 0), #000000);
}

.tag-label {
    position: absolute;
    left: 30px;
    top: 10px;
    color: #fff;
    z-index: 2;
    font-size: 16px;
    width: 40%;

    span.tag-edit-btn {
        color: #0bbddd;
        text-decoration: none;
        margin-left: 5px;
        font-weight: normal;
        font-size: 14px;
        cursor: pointer;
    }
}

.tag-label-edit {
    display: none;

    .pull-left.select-div {
        position: relative;

        select {
            background: transparent;
            -webkit-appearance: none;
            -moz-appearance: none;
            border: 1px solid #fff;
            border-radius: 0;
            padding: 2px 40px 2px 10px;
            color: #fff;
        }
    }


}

.control-btn {
    position: absolute;
    top: 5px;
    right: 20px;
    z-index: 2;

    div {
        float: left;
        background: #fff;
        -webkit-border-radius: 3px;
        border-radius: 3px;
        height: 28px;
        line-height: 26px;
        width: 28px;
        text-align: center;
        margin-right: 10px;

        img {
            max-width: 100%;
            height: auto;
            cursor: pointer;
            padding: 0 !important;
        }
    }

}

.main-pan {
    position: relative;
    overflow: hidden;
    width: 100%;
    background: rgba(0, 0, 0, .85);
    height: 70vh;

    .main-pan-div {
        height: 100%;
        // display: flex;
        width: 100%;

        .blur-rectangle-box {

            .ReactCrop__crop-selection {
                backdrop-filter: blur(5px);
            }
        }
    }

    .main-pan-div1 {
        border: 1px solid rgba(0, 0, 0, .12);
        float: left;
        width: 100%;

        #scroll {
            width: 100%;
            overflow: hidden;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            max-height: 100%;
        }
    }
}

.slide-btn.next,
.slide-btn.prev {
    top: 50%;
    right: 6%;

    img {
        width: 36px;
        height: 36px;
        position: absolute;
        // top: -72px;
        background: rgba(255, 255, 255, .6);
        // z-index: 9999;
        cursor: pointer;
    }
}

.slide-btn.prev {
    // display: none;
    // top: 48%

    top: 50%;
    left: 0;
}

.img-thumbnails {
    position: relative;
    overflow: hidden;

    ul {
        width: 100%;
        white-space: nowrap;
        position: relative;
        padding: 8px 0;

        li:first-child {
            padding-left: 0;
        }

        li {
            // display: inline-block;
            // margin: 0;
            // padding: 0;
            // // width: 150px;
            // padding: 0;
            // position: relative;
            // overflow: hidden;
            // margin: 0;
            // // height: 80px;
            // background: rgba(0, 0, 0, .24);
            // border: 1px solid rgba(0, 0, 0, .12);
            // opacity: 0.3;

            display: inline-block;
            // margin: 0;
            // padding: 0;
            width: 160px;
            padding: 0;
            position: relative;
            overflow: hidden;
            margin: 0;
            height: 80px;
            background: rgba(0, 0, 0, .24);
            border: 1px solid rgba(0, 0, 0, .12);

            img {
                // width: 100%;
                opacity: .3;
                cursor: pointer;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                max-height: none;
            }

            .seen-tag {
                right: 0px;
                top: 0;
                padding: 2px 6px;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.6);
                font-size: 10px;
                /* z-index: 9999999999999999999; */
                border-radius: 0 2px 0 0;
                position: absolute;
                letter-spacing: 1px;
                /* display: none; */
                cursor: pointer;
            }

            .slider-tag-label {
                margin: 5px 0 0px 0;
                float: left;
                font-size: 11px;
                text-overflow: ellipsis;
                overflow-x: hidden;
                width: 100%;
                cursor: pointer;
                position: absolute;
                bottom: 0;
                padding: 4px 10px;
                color: #fff;
                background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
                background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
            }
        }

        li.active {
            opacity: 1;
        }
    }
}

.thumb-control {
    position: relative;

    span {
        cursor: pointer;
    }

    .thumb-next {

        img {
            right: 0;
        }
    }

    img {
        width: 36px;
        height: 36px;
        position: absolute;
        top: -72px;
        background: rgba(255, 255, 255, .6);
        z-index: 9;
    }
}

.container {
    position: relative;
}

/* Hide the images by default */
.mySlides {
    display: none;

    cursor: move;

    img {
        max-height: 100%;

    }
}

.mySlides.active {
    display: flex !important;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    max-height: 100%;
    // width: 100%;
    // height: 100%;
    margin: auto;
}

/* Add a pointer when hovering over the thumbnail images */
.cursor {
    cursor: pointer;
}

#scroll {

    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    max-height: 100%;
    text-align: center;

    .prev,
    .next {
        cursor: pointer;
        position: absolute;
        // top: 40%;
        width: 36px;
        height: 36px;
        padding: 16px;
        margin-top: -50px;
        color: white;
        font-weight: bold;
        font-size: 20px;
        border-radius: 0 3px 3px 0;
        user-select: none;
        -webkit-user-select: none;
    }

    /* Position the "next button" to the right */
    .next {
        right: 25px;
        border-radius: 3px 0 0 3px;
    }



}

.gallery-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;

    .next {
        right: 25px;

        img {
            right: 0px;
        }
    }

}


/* Next & previous buttons */


/* On hover, add a black background color with a little bit see-through */
// .prev:hover,
// .next:hover {
//     background-color: rgba(0, 0, 0, 0.8);
// }

/* Number text (1/3 etc) */
.numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
}

/* Container for image text */
.caption-container {
    text-align: center;
    background-color: #222;
    padding: 2px 16px;
    color: white;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

/* Six columns side by side */
.column {
    // float: left;
    width: 16.66%;
}

/* Add a transparency effect for thumnbail images */
.demo {
    opacity: 0.6;
}

.active,
.demo:hover {
    // opacity: 1;
}

.vehicle-img-gallery {
    .inspection-dropdown {
        color: #000000;
    }
}


/******CORRECT PANEL CSS*******/

.correction-panel {
    .reqc_imageupload_taglist {
        border-radius: 2px;
        padding: 10px;
        cursor: pointer;
        margin-bottom: 15px;

    }

    .re-qc-list-active {
        background: #f5f5f5;
        border: 1px solid #ef5a28;
        color: #ef5a28;
        font-weight: bold;
    }

    .re-qc-list-default {
        background: #fff;
        border: 1px solid #eee;
        color: #999;
        font-weight: normal;
    }

    .car-image-container {
        display: inline-grid;
        padding: 5px;
        text-align: center;
        background: #f0f0f0;
        // height: 150px;
        border-radius: 8px;
        margin: 15px 8px 16px;
        position: relative;
        min-height: 102px;
        height: 102px;
        width: 132px;
    }

    .panel-btns {
        .btn {
            min-width: 100px !important;
        }
    }

    // .car-image-box {
    //     border: 1px solid #f0f0f0;
    //     background: #c4c4c4;
    //     border-radius: 8px;
    //     max-height: 102px;
    //     max-width: 100%;
    //     /* object-fit: cover; */
    //     height: 100%;
    //     position: relative;

        .del {
            background: rgba(0, 0, 0, 0.6);
            width: 100%;
            height: 100%;
            // padding: 18px;
            z-index: 1;
            color: #fff;
            position: absolute;
            top: 0;

            .del-txt {
                padding-top: 20px;
                ul {
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                    cursor: pointer;
                }
            }
        }

        // ul{
        //     text-align: center;
        //     li {
        //         float: left;
        //     }
        // }


        .ic-clearclose {
            font-size: 16px;
            color: rgba(0, 0, 0, .34);
            padding: 0;
            width: 20px;
            height: 20px;
            right: -10px;
            margin-right: 0;
            margin-top: 0;
            cursor: pointer;
            top: -10px;
            text-align: center;
            background-color: #f3f2f2;
            border-radius: 50px;
            position: absolute;

            border: 1px solid #dddddd;
            z-index: 9;
        }
    // }

    .car-gallery {
        .img-outer {
            img {
                width: 120px;
                height: 90px;
                object-fit: contain;

            }
        }
    }
}

.re-qc-upload-box {
    .car-image-container {
        float: left !important;
    }
}

.edit-inspected-cars {
    background-color: $white;
    padding-bottom: 20px;
}

/******CORRECT PANEL CSS END*******/